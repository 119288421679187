import { Actions, Mutations } from "@/store/enums/StoreEnums";
import store  from "../index";

import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";



@Module({name: "BlacklistModal", store: store})
export default class BlacklistModal extends VuexModule{
    isupdate = false;
    isLoading = false;

    
        blackListData = {
                FirstName: "",
                LastName:"",
                FatherName:"",
                CNIC: "",
                Passport: "",
        };
    
            nacta = [];
            unsc = [];
    get getUpdate(){
        return this.isupdate;
    }
    get getBlacklistData(){
        return this.blackListData;
    }
    get getBlacklistResultNacta(){
        return this.nacta;
    }

    get getIsLoading(){
        return this.isLoading;
    }
    get getBlacklistResultUnsc(){
        return this.unsc;
    }


    @Mutation
    updateToggle(isupdate: boolean){this.isupdate = isupdate;}
    @Action 
    setUpdate(isupdate: boolean){this.updateToggle(isupdate);}
    // Blacklistcheck store
    @Mutation
    updateBlacklist(Blacklist){this.blackListData = Blacklist;}
    @Action 
    setBlacklist(Blacklist){this.updateBlacklist(Blacklist);}
     // Blacklistcheck result
     @Mutation
     updateBlacklistResultnacta(checkResponse){this.nacta = checkResponse;}
     @Mutation
     updateBlacklistResultunsc(checkResponse: []){this.unsc = checkResponse;}
     @Action 
     setBlacklistResultnacta(checkResponse){this.updateBlacklistResultnacta(checkResponse);}
     @Action 
     setBlacklistResultunsc(checkResponse){this.updateBlacklistResultunsc(checkResponse);}
     // Set Is loading
     @Mutation
     updateIsLoading(isloading: boolean){this.isLoading = isloading;}
     @Action 
     SetIsLoading(isloading: boolean){this.updateIsLoading(isloading);}

    
}
