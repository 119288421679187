import store  from "../index";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module({name: "UpdateDonor", store: store})
export default class UpdateDonor extends VuexModule{
    isupdatedonor = false;
    isupdateBod = false;
    isupdateOwner = false;
    isupdateProject = false;
    donorId = 0;
    projectId = 0;
    ownerId = 0;
    bodId = 0;
    donorData: any = {};
    list: any = {};
    listDataTable = false;
    get getDonorData(){return this.donorData;}
    get getdonorDatatable(){return this.listDataTable;}
    get getdonorlistData(){return this.list;}
    get getBodUpdate(){return this.isupdateBod;}
    get getOwnerUpdate(){return this.isupdateOwner;}
    get getProjectUpdate(){return this.isupdateProject;}
    get getDonorId(){return this.donorId;}
    get getDonorUpdate(){ return this.isupdatedonor;}
    get getBodId(){ return this.bodId;}
    get getOwnerId(){ return this.ownerId;}
    get getProjectId(){ return this.projectId;}
     
    // Donor Update Donor
     @Mutation
     updateDonor(isupdatedonor: boolean){this.isupdatedonor = isupdatedonor;}
     @Action
     SetupdateDonor(isupdatedonor: boolean){this.updateDonor(isupdatedonor);}
     
   // Donor Update Bod
    @Mutation
    updateBod(isupdateBod: boolean){this.isupdateBod = isupdateBod;}
    @Action
    SetupdateBod(isupdateBod: boolean){this.updateBod(isupdateBod);}
    // Donor Update Owner
    @Mutation
    updateOwner(isupdateowner: boolean){this.isupdateOwner = isupdateowner;}
    @Action
    SetupdateOwner(isupdateowner: boolean){this.updateOwner(isupdateowner);}
    // Donor Update Project
    @Mutation
    updateProject(isupdateProject: boolean){this.isupdateProject = isupdateProject;}
    @Action
    SetupdateProject(isupdateProject: boolean){this.updateProject(isupdateProject);}
    //Donor Id
    @Mutation
    DonorId(donorId: number){this.donorId = donorId;}
    @Action
    SetDonorId(donorId: number){this.DonorId(donorId);}
    // Project Id
    @Mutation
    UpdateProjectId(projectId: number){this.projectId = projectId;}
    @Action
    SetProjectId(projectId: number){this.UpdateProjectId(projectId);}
    // Owner Id
    @Mutation
    UpdateOwnerId(ownerId: number){this.ownerId = ownerId;}
    @Action
    SetOwnerId(ownerId: number){this.UpdateOwnerId(ownerId);}
    // BOD Id
    @Mutation
    UpdateBodId(bodId: number){this.bodId = bodId;}
    @Action
    SetBodId(bodId: number){this.UpdateBodId(bodId);}
    //
    
    @Mutation
    updateDonorData(updateData: any){this.donorData = updateData;}
    @Action 
    setDonorData(donorData: any){this.updateDonorData(donorData);}

    @Mutation
    updateDonorList(updateData: any){this.list = updateData;}
    @Action 
    setDonorList(donorData: any){this.updateDonorList(donorData);}

    @Mutation
    updateDonorDatatable(updateData: boolean){this.listDataTable = updateData;}
    @Action 
    setDonorDatatable(donorData: boolean){this.updateDonorDatatable(donorData);}
    
}
