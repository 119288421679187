import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/crafted/pages/Dashboards/AdminDashboard.vue"),
        meta: {
          pageTitle: "Dashboard",
          breadcrumbs: ["Dashboards"],
        },
      },
      {
        path: "/data-table",
        name: "datatable",
        component: () => import("@/components/kt-datatable/KTDataTable.vue"),
        meta: {
          pageTitle: "Dashboard",
          breadcrumbs: ["Dashboards"],
        },
      },
      {
        path: "/builder",
        name: "builder",
        component: () => import("@/views/LayoutBuilder.vue"),
        meta: {
          pageTitle: "Layout Builder",
          breadcrumbs: ["Layout"],
        },
      },
      {
        path: "/crafted/pages/donors/adddonors",
        name: "add-donor",
        component: () => import("@/views/crafted/pages/Donors/AddDonors.vue"),
        meta: {
          pageTitle: "Donors",
          breadcrumbs: ["Pages", "Add Donors"],
        },
      },
      {
        path: "/donors/donorslist/:status",
        name: "view-donors",
        component: () => import("@/views/crafted/pages/Donors/DonorListing.vue"),
        props: true,
        meta: {
          pageTitle: "Donors",
          breadcrumbs: ["Donors", "Donors List"],
        },
      },
      {
        path: "/crafted/pages/donors/viewdonorsdatatable",
        name: "view-donor-datatable",
        component: () => import("@/views/crafted/pages/Donors/viewDataTable.vue"),
        meta: {
          pageTitle: "Donors",
          breadcrumbs: ["Donors", "Donors List View"],
        },
      },
      {
        path: "/employees/addemployees",
        name: "add-employees",
        component: () => import("@/views/crafted/pages/Employees/AddEmployees.vue"),
        meta: {
          pageTitle: "Employees",
          breadcrumbs: ["View", "Add Employees"],
        },
      },
      {
        path: "/employees/viewemployees",
        name: "view-employees1",
        component: () => import("@/views/crafted/pages/Employees/ViewEmployees.vue"),
        meta: {
          pageTitle: "Employees",
          breadcrumbs: ["Pages", "View Employees"],
        },
      },
      {
        path: "/employees/employeeslist/:status",
        name: "view-employees",
        component: () => import("@/views/crafted/pages/Employees/EmployeesList.vue"),
        props: true,
        meta: {
          pageTitle: "Employees",
          breadcrumbs: ["Pages", "Employees Data"],
        },
      },
      {
        path: "/LSO/addlso",
        name: "add-lso",
        component: () => import("@/views/crafted/pages/LSOs/AddLSO.vue"),
        meta: {
          pageTitle: "LSOs/VOs/WOs",
          breadcrumbs: ["Pages", "View LSOs/VOs/WOs"],
        },
      },
      {
        path: "/LSO/viewlso/:status",
        name: "view-lso",
        props: true,
        component: () => import("@/views/crafted/pages/LSOs/LsosList.vue"),
        meta: {
          pageTitle: "LSOs/VOs/WOs",
          breadcrumbs: ["Pages", "Add LSOs/VOs/WOs"],
        },
      },
      {
        path: "/Others/addothers",
        name: "add-others",
        component: () => import("@/views/crafted/pages/Others/AddOthers.vue"),
        meta: {
          pageTitle: "Others",
          breadcrumbs: ["Pages", "Add Others"],
        },
      },
      {
        path: "/Others/viewothers/:status",
        name: "view-others",
        props: true,
        component: () => import("@/views/crafted/pages/Others/ViewOthers.vue"),
        meta: {
          pageTitle: "Others",
          breadcrumbs: ["Pages", "View Others"],
        },
      },
      {
        path: "/users/addusers",
        name: "add-users",
        component: () => import("@/views/crafted/pages/Users/AddUser.vue"),
        meta: {
          pageTitle: "Users",
          breadcrumbs: ["Pages", "Add Users"],
        },
      },
      {
        path: "/users/editusers/:id",
        name: "edit-users",
        component: () => import("@/views/crafted/pages/Users/EditUser.vue"),
        meta: {
          pageTitle: "Users",
          breadcrumbs: ["Pages", "Edit Users"],
        },
      },
      {
        path: "/users/viewusers",
        name: "view-users",
        component: () => import("@/views/crafted/pages/Users/ViewUsers.vue"),
        meta: {
          pageTitle: "Users",
          breadcrumbs: ["Pages", "View Users"],
        },
      },
      {
        path: "/bulkcheck/employees",
        name: "bulkcheck-employees",
        component: () => import("@/views/crafted/pages/Bulkblacklistings/BulkcheckEmployees.vue"),
        meta: {
          pageTitle: "On-Demand Check Employees",
          breadcrumbs: ["Pages", "Employees"],
        },
      },
      {
        path: "/bulkcheck/donors",
        name: "bulkcheck-donors",
        component: () => import("@/views/crafted/pages/Bulkblacklistings/BulkcheckDonor.vue"),
        meta: {
          pageTitle: "On-Demand Check Donors",
          breadcrumbs: ["Pages", "Donors"],
        },
      },
      {
        path: "/bulkcheck/references",
        name: "bulkcheck-references",
        component: () => import("@/views/crafted/pages/Bulkblacklistings/BulkcheckEmployeesReferences.vue"),
        meta: {
          pageTitle: "On-Demand Check Employees References",
          breadcrumbs: ["Pages", "Donors"],
        },
      },
      {
        path: "/bulkcheck/donors-bod",
        name: "bulkcheck-donors-bod",
        component: () => import("@/views/crafted/pages/Bulkblacklistings/BulkcheckDonorsBod.vue"),
        meta: {
          pageTitle: "On-Demand Check Donors BOD",
          breadcrumbs: ["Pages", "Donors BOD"],
        },
      },
      {
        path: "/bulkcheck/donors-owners",
        name: "bulkcheck-donors-owners",
        component: () => import("@/views/crafted/pages/Bulkblacklistings/BulkcheckDonorsOwners.vue"),
        meta: {
          pageTitle: "On-Demand Check Donors Owners",
          breadcrumbs: ["Pages", "Donors Owners"],
        },
      },
      {
        path: "/bulkcheck/lso-bod",
        name: "bulkcheck-lso-bod",
        component: () => import("@/views/crafted/pages/Bulkblacklistings/BulkcheckLsoBod.vue"),
        meta: {
          pageTitle: "On-Demand Check LSO/VO/WO BODS",
          breadcrumbs: ["Pages", "LSO/VO/WO BODS"],
        },
      },
      {
        path: "/bulkcheck/lso-contact",
        name: "bulkcheck-lso-contact",
        component: () => import("@/views/crafted/pages/Bulkblacklistings/BulkcheckLsoContact.vue"),
        meta: {
          pageTitle: "On-Demand Check Donors",
          breadcrumbs: ["Pages", "LSO/WO/VO Contact Persons"],
        },
      },
      {
        path: "/bulkcheck/others",
        name: "bulkcheck-others",
        component: () => import("@/views/crafted/pages/Bulkblacklistings/BulkcheckOthers.vue"),
        meta: {
          pageTitle: "On-Demand Check Others",
          breadcrumbs: ["Pages", "Others Ondemand Check"],
        },
      },
      {
        path: "/bulkcheck/others-owners",
        name: "bulkcheck-other-owners",
        component: () => import("@/views/crafted/pages/Bulkblacklistings/BulkcheckOtherOwners.vue"),
        meta: {
          pageTitle: "On-Demand Check Donors",
          breadcrumbs: ["Pages", "Other's Owners"],
        },
      },
      {
        path: "/bulkcheck/others-bod",
        name: "bulkcheck-other-Bod",
        component: () => import("@/views/crafted/pages/Bulkblacklistings/BulkcheckOthersBod.vue"),
        meta: {
          pageTitle: "On-Demand Check Donors",
          breadcrumbs: ["Pages", "Other's Bod"],
        },
      },
     
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "Sign In",
        },
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
        meta: {
          pageTitle: "Sign Up",
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
        meta: {
          pageTitle: "Password reset",
        },
      },
    ],
  },
  {
    path: "/multi-step-sign-up",
    name: "multi-step-sign-up",
    component: () =>
      import("@/views/crafted/authentication/MultiStepSignUp.vue"),
    meta: {
      pageTitle: "Multi-step Sign up",
    },
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // current page view title
  document.title = `${to.meta.pageTitle} - ${process.env.VUE_APP_NAME}`;

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // verify auth token before each page change
  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // before page access check if page requires authentication
  if (to.meta.middleware == "auth") {
    if (store.getters.isUserAuthenticated) {
      next();
    } else {
      next({ name: "sign-in" });
    }
  } else {
    next();
  }

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;
