import store  from "../index";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module({name: "UpdateOther", store: store})
export default class UpdateOther extends VuexModule{
    isupdateOther = false;
    isupdateBodOther = false;
    isupdateOwnerOther = false;
    otherId = 0;
    otherOwnerId = 0;
    otherBodId = 0;
    otherData: any = {};
    list: any = {};
    listDataTable = false;
    get getOtherData(){return this.otherData;}
    get getOtherDatatable(){return this.listDataTable;}
    get getOtherlistData(){return this.list;}
    get getOtherBodUpdate(){return this.isupdateBodOther;}
    get getOtherOwnerUpdate(){return this.isupdateOwnerOther;}
    get getOtherId(){return this.otherId;}
    get getOtherUpdate(){ return this.isupdateOther;}
    get getOtherBodId(){ return this.otherBodId;}
    get getOtherOwnerId(){ return this.otherOwnerId;}
     
    // Other Update Donor
     @Mutation
     updateOther(isupdateother: boolean){this.isupdateOther = isupdateother;}
     @Action
     SetupdateOther(isupdateother: boolean){this.updateOther(isupdateother);}
     
   // Donor Update Bod
    @Mutation
    updateOtherBod(isupdateBod: boolean){this.isupdateBodOther = isupdateBod;}
    @Action
    SetupdateOtherBod(isupdateBod: boolean){this.updateOtherBod(isupdateBod);}
    // Other Update Owner
    @Mutation
    updateOtherOwner(isupdateowner: boolean){this.isupdateOwnerOther = isupdateowner;}
    @Action
    SetupdateOtherOwner(isupdateowner: boolean){this.updateOtherOwner(isupdateowner);}
    //Other Id
    @Mutation
    OtherId(otherId: number){this.otherId = otherId;}
    @Action
    SetOtherId(otherId: number){this.OtherId(otherId);}
   // Owner Id
    @Mutation
    UpdateOtherOwnerId(ownerId: number){this.otherOwnerId = ownerId;}
    @Action
    SetOtherOwnerId(ownerId: number){this.UpdateOtherOwnerId(ownerId);}
    // BOD Id
    @Mutation
    UpdateOtherBodId(bodId: number){this.otherBodId = bodId;}
    @Action
    SetOtherBodId(bodId: number){this.UpdateOtherBodId(bodId);}
    //
    
    @Mutation
    updateOtherData(updateData: any){this.otherData = updateData;}
    @Action 
    setOtherData(otherData: any){this.updateOtherData(otherData);}

    @Mutation
    updateOtherList(updateData: any){this.list = updateData;}
    @Action 
    setOtherList(otherData: any){this.updateOtherList(otherData);}

    @Mutation
    updateOtherDatatable(updateData: boolean){this.listDataTable = updateData;}
    @Action 
    setOtherDatatable(otherData: boolean){this.updateOtherDatatable(otherData);}
    
}
