import { Actions, Mutations } from "@/store/enums/StoreEnums";
import store  from "../index";
import ref from "vue";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module({name: "updateEmp", store: store})
export default class UpdateEmp extends VuexModule{
    isupdate = false;
    isupdatereferece = false;
    referenceId = 0;
    empData: any = {};
    list: any = {};
    listDataTable = false;
    get getUpdate(){return this.isupdate;}
    get getempData(){return this.empData;}
    get getempDatatable(){return this.listDataTable;}
    get getlistData(){return this.list;}
    get getUpdateReferece(){return this.isupdatereferece;}
    get getReferenceId(){return this.referenceId;}

    @Mutation
    updateToggle(isupdate: boolean){this.isupdate = isupdate;}
    @Action 
    setUpdate(isupdate: boolean){this.updateToggle(isupdate);}
    // Referece store

    @Mutation
    updateToggleReference(isupdatereferece: boolean){this.isupdatereferece = isupdatereferece;}
    @Action
    setUpdateReference(isupdatereferece: boolean){this.updateToggleReference(isupdatereferece);}
    @Mutation 
    updateReferenceId(referenceId: number){this.referenceId = referenceId;}
    @Action
    SetReferenceId(referenceId: number){this.updateReferenceId(referenceId);}
   

    //
    
    @Mutation
    updateempData(updateData: any){this.empData = updateData;}
    @Action 
    setempData(empData: any){this.updateempData(empData);}

    @Mutation
    updateempList(updateData: any){this.list = updateData;}
    @Action 
    setempList(empData: any){this.updateempList(empData);}

    @Mutation
    updateEmpDatatable(updateData: boolean){this.listDataTable = updateData;}
    @Action 
    setEmpDatatable(empData: boolean){this.updateEmpDatatable(empData);}
    
}
