import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import ConfigModule from "@/store/modules/ConfigModule";
import ThemeModeModule from "@/store/modules/ThemeModeModule";
import UpdateEmp from "@/store/modules/UpdateEmp";
import UpdateDonor from "@/store/modules/UpdateDonor";
import BlacklistModal from "@/store/modules/BlacklistModal";
import UpdateLso from "@/store/modules/UpdateLso";
import UpdateOther from "@/store/modules/UpdateOther";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    ConfigModule,
    ThemeModeModule,
    UpdateEmp,
    BlacklistModal,
    UpdateDonor,
    UpdateLso,
    UpdateOther
  },
});

export default store;
