import store  from "../index";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module({name: "UpdateLso", store: store})
export default class UpdateLso extends VuexModule{
    isupdateLso = false;
    isupdateGrant = false;
    isupdateContactPerson = false;
    isupdateBod = false;
    lsoId = 0;
    grantId = 0;
    contactPersonId = 0;
    lsobodId = 0;
    lsoData: any = {};
    list: any = {};
    listDataTable = false;
    get getUpdateLso(){return this.isupdateLso;}
    get getUpdateGrant(){return this.isupdateGrant;}
    get getUpdateContactPerson(){return this.isupdateContactPerson;}
    get getUpdateBod(){return this.isupdateBod;}
    get getLsoData(){return this.lsoData;}
    get getLsoDatatable(){return this.listDataTable;}
    get getLsolistData(){return this.list;}
    get getLsoId(){return this.lsoId;}
    get getGrantId(){return this.grantId;}
    get getContactPersonId(){return this.contactPersonId;}
    get getLsoBodId(){return this.lsobodId;}

    @Mutation
    updateLsoToggle(isupdate: boolean){this.isupdateLso = isupdate;}
    @Action 
    SetLsoUpdate(isupdate: boolean){this.updateLsoToggle(isupdate);}

    @Mutation 
    UpdateLsoId(lsoId: number){this.lsoId = lsoId;}
    @Action
    SetLsoId(lsoId: number){this.UpdateLsoId(lsoId);}
   
    // Grant store

    @Mutation
    updateGrantIsUpdate(isupdateGrant: boolean){this.isupdateGrant = isupdateGrant;}
    @Action
    SetUpdateGrant(isupdatereferece: boolean){this.updateGrantIsUpdate(isupdatereferece);}
    //Grant ID
    @Mutation 
    updateGrantId(grantId: number){this.grantId = grantId;}
    @Action
    SetGrantId(grantId: number){this.updateGrantId(grantId);}
   
     //Contact Person's ID
     @Mutation 
     updateContactPersonId(cpId: number){this.contactPersonId = cpId;}
     @Action
     SetContactPersonId(cpId: number){this.updateContactPersonId(cpId);}
    // COntact Person's update
     @Mutation
    updateContactPersonIsUpdate(isupdatecp: boolean){this.isupdateContactPerson = isupdatecp;}
    @Action
    SetUpdateContactPerson(isupdatecp: boolean){this.updateContactPersonIsUpdate(isupdatecp);}
    

     //Bod ID
     @Mutation 
     updateLsoBodId(bodId: number){this.lsobodId = bodId;}
     @Action
     SetBodId(bodId: number){this.updateLsoBodId(bodId);}

     @Mutation
     updateBodIsUpdate(isupdatecp: boolean){this.isupdateBod = isupdatecp;}
     @Action
     SetUpdateBod(isupdatecp: boolean){this.updateBodIsUpdate(isupdatecp);}
     

    //
    
    @Mutation
    updateLsoData(updateData: any){this.lsoData = updateData;}
    @Action 
    setLsoData(lsoData: any){this.updateLsoData(lsoData);}

    @Mutation
    updateLsoList(updateData: any){this.list = updateData;}
    @Action 
    setLsoList(lsoData: any){this.updateLsoList(lsoData);}

    @Mutation
    updateLsoDatatable(updateData: boolean){this.listDataTable = updateData;}
    @Action 
    setLsoDatatable(lsoData: boolean){this.updateLsoDatatable(lsoData);}
    
}
